.landing-container {
  width: 100vw;
  height: 100vh;
}

.landing-content {
  height: 75%;
  width: 60%;
  margin: auto;
  padding-top: 10rem;
  margin-top: 15vh;
  display: flex;
}

.landing-description {
  display: flex;
  max-width: 500px;
}

.landing-description h1 {
  font-size: 3rem;
  color: #000000;
  margin-bottom: 1rem;
  line-height: 1;
  letter-spacing: 20;
}

.landing-navs {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: space-between;
  margin-top: 2em;
  margin-bottom: 4em;
}

// .landing-description-content {
//   width: 60%;
// }

.gallium-badge {
  margin-left: 0.5em;
  margin-bottom: 2rem;
}

.logo-container {
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-container {
  margin-top: 2.5rem;
}

// Media Queries

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .landing-content {
    width: 85%;
    padding-top: 5rem;
    margin-top: 5vh;
  }

  .landing-description h1 {
    font-size: 2rem;
  }

  .landing-navs {
    width: 280px;
  }

  .landing-description {
    display: block;
    max-width: 350px;
  }
}

// Small - Medium devices
@media (min-width: 600px) and (max-width: 634px) {
  .landing-content {
    width: 85%;
  }

  .landing-description {
    display: block;
    max-width: 350px;
  }
}

// Small - Medium devices
@media (min-width: 635px) and (max-width: 819px) {
  .landing-content {
    width: 85%;
  }

  .landing-description {
    display: block;
  }
}

// Big phones and Small Tablets
@media (min-width: 820px) and (max-width: 944px) {
  .landing-content {
    width: 85%;
  }
}

// Tablets  and small screens
@media (min-width: 945px) and (max-width: 1199px) {
  .landing-content {
    width: 85%;
  }
}

// Tablets Landscape and Medium  Screens
@media (min-width: 1200px) and (max-width: 1440px) {
  .landing-content {
    width: 85%;
  }
}

// Large - Extra Large Screens
@media (min-width: 1441px) and (max-width: 1920px) {
  .landing-content {
    width: 75%;
  }
}

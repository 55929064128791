.forecasting-content {
  margin-top: 4rem;
}
.forecasting-description {
  width: 600px;
  margin-right: auto;
}

.forecasting-description p {
  margin-bottom: 2rem;
}

.forecasting-description h4 {
  margin-bottom: 0.5rem;
}

.forecasting-link {
  border: 1px dashed #039874;
  width: 350px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: "4rem";
  padding: 10px;
  border-radius: 10px;
  gap: 1rem;
  cursor: pointer;
}

.forecasting-link:hover {
  background-color: #08b78b0e;
}

.data-input {
  margin-top: 4rem;
}

.data-upload {
  margin-bottom: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}

.data-upload p {
  margin: 0;
}

// Media Queries

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .forecasting-description {
    width: 100%;
  }

  .forecasting-link {
    width: 100%;
    gap: 0.5rem;
  }
}

// Small - Medium devices
@media (min-width: 600px) and (max-width: 634px) {
  .forecasting-description {
    width: 100%;
  }
  .forecasting-link {
    width: 100%;
    gap: 0.5rem;
  }
}

// Small - Medium devices
@media (min-width: 635px) and (max-width: 819px) {
}

// Big phones and Small Tablets
@media (min-width: 820px) and (max-width: 944px) {
}

// Tablets  and small screens
@media (min-width: 945px) and (max-width: 1199px) {
}

// Tablets Landscape and Medium  Screens
@media (min-width: 1200px) and (max-width: 1440px) {
}

// Large - Extra Large Screens
@media (min-width: 1441px) and (max-width: 1920px) {
}

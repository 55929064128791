.bx--data-table {
  margin-top: 2rem;
}

.bx--data-table th {
  background-color: #039874;
  color: #ffffff;
  font-family: "display-text-medium";
}

.bx--data-table td {
  background-color: #ffffff;

  font-family: "display-text";
}

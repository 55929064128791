// Upload Button

// .upload {
//   margin-top: 2rem;
// }

.bx--file-browse-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  max-width: 400px;
  height: 250px;
  margin: 0;
  cursor: pointer;
}

.bx--file-browse-btn:hover {
  background-color: #08b78b0e;
}

.bx--file__drop-container {
  width: 100%;
  height: 100%;
  padding: 2rem;
  border: 1px dashed #e0e0e0;
  border-radius: 10px;
}

.upload-button-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.bx--file-browse-btn:hover {
  outline: none;
  border: none;
  text-decoration: none;
}

.bx--file-browse-btn:focus {
  outline: none;
  border: none;
  text-decoration: none;
}

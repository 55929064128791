.button {
  width: 140px;
  height: 45px;
  background-color: #039874;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.button-back {
  background-color: #df3229;
}

.button:hover {
  background-color: #08b78c;
  text-decoration: none;
}

.button-back:hover {
  background-color: #df3229b9;
}

.button h4 {
  font-size: 1em;
  color: #ffffff;
  margin-bottom: 0rem;
  line-height: 1.5;
  padding-left: 0em;
  width: 100%;
  text-align: center;
}

.button-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.chat-content {
  margin-top: 4rem;
}
.chat-description {
  width: 600px;
  margin-right: auto;
}

.chat-description p {
  margin-bottom: 1.5rem;
}

.chat-description h4 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.chat-link {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
  cursor: pointer;
}

.chat-link:hover {
  text-decoration: underline;
}

.start-chat {
  width: 100%;
  max-width: 600px;
  height: 140px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  margin-top: 4rem;
}

.start-chat-error {
  border: 1px solid red;
}
.start-chat input {
  width: 100%;
  height: 45px;
  margin-top: 1.2rem;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 2rem;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  margin-bottom: 1rem;
}

.start-chat-error input {
  border-bottom: 1px solid red;
}

.start-chat input::placeholder {
  font-size: 1em;
  font-family: "display-text", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  color: #c6c6c6;
}

.start-chat input:focus {
  outline: none;
}

.start-chat button h4 {
  margin: 0;
  font-size: 1rem;
  width: 100%;
}

.start-chat button h4:hover {
  color: #039874;
}

.start-chat button:hover {
  margin: 0;
  color: #039874;
}
.start-chat button {
  padding-left: 2rem;
  width: 100%;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.chat-content {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 2rem;
}
.chat-container {
  width: 600px;
  height: 600px;
  margin-right: auto;
  margin-top: 2rem;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
}

.chat-content a {
  text-decoration: underline;
  color: #039874;
  font-size: 1em;
}

.refresh-link {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  cursor: pointer;
}

.refresh-link p {
  color: #039874;
}
.refresh-link:hover {
  text-decoration: underline;
  color: #039874;
}
// Chat container

.chat-container {
  width: 600px;
  height: 600px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
}

.chat-container-input {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.chat-container button {
  padding-left: 2rem;
  width: 80px;
  height: 35px;
  border: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  background-color: white;
}

.chat-container input {
  width: 85%;
  height: 35px;
  margin-top: 1.2rem;
  border: none;
  padding-left: 2rem;
  font-size: 16px;
  font-family: "display-text", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  margin-bottom: 1rem;
}

.chat-container input::placeholder {
  font-size: 1em;
  font-family: "display-text", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  color: #c6c6c6;
}

.chat-container input:focus {
  outline: none;
}

.chat-container-message {
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 85%;
  overflow-y: scroll;
}

.chat-container-message-human {
  background-color: #039874;
  max-width: 300px;
  padding: 0.8rem;
  border-radius: 10px;
  align-self: flex-start;
}

.chat-container-message-human p {
  color: #ffffff;
}

.chat-container-message-bot {
  background-color: #e0e0e081;
  max-width: 300px;
  padding: 0.8rem;
  border-radius: 10px;
  align-self: flex-end;
}

.chat-container-message-bot p {
  color: #000000;
}

.slider-content a {
  text-decoration: underline;
  color: #039874;
  font-size: 1em;
}
// Media Queries

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .chat-description {
    width: 300px;
    margin-right: auto;
  }

  .start-chat {
    width: 300px;
    height: 140px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    margin-top: 4rem;
  }

  .chat-container {
    width: 100%;
    height: 600px;
  }

  .chat-container input {
    padding-left: 1rem;
  }
}

// Small - Medium devices
@media (min-width: 600px) and (max-width: 634px) {
  .chat-description {
    width: 300px;
    margin-right: auto;
  }
  .start-chat {
    width: 300px;
    height: 140px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    margin-top: 4rem;
  }
  .chat-container {
    width: 300px;
    height: 600px;
  }

  .chat-container input {
    padding-left: 1rem;
  }
}

// Small - Medium devices
@media (min-width: 635px) and (max-width: 819px) {
}

// Big phones and Small Tablets
@media (min-width: 820px) and (max-width: 944px) {
}

// Tablets  and small screens
@media (min-width: 945px) and (max-width: 1199px) {
}

// Tablets Landscape and Medium  Screens
@media (min-width: 1200px) and (max-width: 1440px) {
}

// Large - Extra Large Screens
@media (min-width: 1441px) and (max-width: 1920px) {
}

.text-input {
  min-width: 250px;
  max-width: 400px;
  margin-bottom: 1rem;
}
.text-input input {
  width: 100%;
  height: 45px;
  border: 1px solid hsl(0, 0%, 88%);
  border-radius: 5px;
  padding: 1rem;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 0.5rem;
}

.error-text {
  color: red;
  margin-left: 1.2rem;
}

.input-error {
  border: 1px solid red;
}
// input[type="text"] {
//   /* Remove */
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;

//   // /* Optional */
//   // box-shadow: none;
// }

.text-input input::placeholder {
  font-size: 1em;
  font-family: "display-text", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  color: #c6c6c6;
}

.text-input input:focus {
  outline: none;
}

// App Navbar
.app-nav {
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff;
  z-index: 1000;
}

.app-nav-container {
  width: 60%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

.app-nav .nav-tabs {
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.app-nav .nav-actions {
  flex-basis: 0%;
  display: flex;
  height: 100%;
}

.app-nav .nav-action-1,
.app-nav .nav-action-2,
.app-nav .nav-action-3,
.app-nav .nav-action-4,
.app-nav .nav-action-5 {
  width: 100px;
}
.app-nav .nav-tabs a {
  color: #000000;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

// Options

.options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  cursor: pointer;
}

.options-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Switcher

.nav-switcher {
  display: none;
}

.slider-menu {
  display: none;
}

// Media Queries

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .app-nav-container {
    width: 85%;
    justify-content: space-between;
  }

  .app-nav {
    cursor: pointer;
  }
  .app-nav .nav-actions {
    display: none;
  }
  .nav-switcher {
    display: block;
  }

  // Switcher

  .slider-menu {
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    z-index: 10000;
  }

  .slider-menu-container {
    width: 85%;
    margin: auto;

    padding-top: 50px;
  }

  .slider-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    cursor: pointer;
  }

  .slider-item a {
    color: #039874;
    font-family: "display-text-medium";
    font-size: 1.2em;
    cursor: pointer;
    line-height: 1.2;
  }

  .slider-item a:hover {
    color: #adbac7;
  }
}

// Small - Medium devices
@media (min-width: 600px) and (max-width: 634px) {
  .app-nav-container {
    width: 85%;
    justify-content: space-between;
  }
  .app-nav {
    justify-content: space-between;
    cursor: pointer;
  }
  .app-nav .nav-actions {
    display: none;
  }
  .nav-switcher {
    display: block;
  }

  // Switcher

  .slider-menu {
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #1e2329;
  }

  .slider-menu-container {
    width: 85%;
    margin: auto;

    padding-top: 50px;
  }

  .slider-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    cursor: pointer;
  }

  .slider-item a {
    color: #adbac7;
    font-family: "display-text-medium";
    font-size: 1.2em;
    cursor: pointer;
    line-height: 1.2;
  }

  .slider-item a:hover {
    color: #539bf5;
  }
}

// Small - Medium devices
@media (min-width: 635px) and (max-width: 819px) {
  .app-nav-container {
    width: 85%;
  }
}

// Big phones and Small Tablets
@media (min-width: 820px) and (max-width: 944px) {
  .app-nav-container {
    width: 85%;
  }
}

// Tablets  and small screens
@media (min-width: 945px) and (max-width: 1199px) {
  .app-nav-container {
    width: 85%;
  }
}

// Tablets Landscape and Medium  Screens
@media (min-width: 1200px) and (max-width: 1440px) {
  .app-nav-container {
    width: 85%;
  }
}

// Large - Extra Large Screens
@media (min-width: 1441px) and (max-width: 1920px) {
  .app-nav-container {
    width: 75%;
  }
}

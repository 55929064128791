// Text
h1 {
  letter-spacing: 0.5px;
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-weight: 400;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0px;
  margin: 0px;
  line-height: 1.2;
}

h2 {
  letter-spacing: normal;
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-weight: 400;
  font-size: 2.5rem;
  color: #039874;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: 1;
}

h4 {
  letter-spacing: normal;
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-weight: 600;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: 1;
  font-size: 1rem;
}

p {
  font-size: 0.9em;
  letter-spacing: normal;
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-weight: 400;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
}

a {
  font-size: 0.9em;
  letter-spacing: normal;
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  color: #039874;
  text-decoration: none;
  cursor: pointer;
}

small {
  font-size: 0.8em;
  letter-spacing: normal;
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  color: #999999;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
}

body {
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container {
  width: 100vw;
  min-height: 95vh;
  position: relative;
  padding-bottom: 5rem;
}

.content {
  min-height: 75%;
  width: 60%;
  margin: auto;
  padding-top: 3rem;
}

.header {
  margin-top: 2rem;
}

.slider {
  position: absolute;
  top: 0px;
  right: 0;
  left: auto;
  height: 100%;
  width: 500px;
  z-index: 99;
  display: block;
  border-left: 1px solid #e0e0e0;
  padding: 1rem;
  background-color: #ffffff;
}

.slider-out {
  display: none;
}

.slider-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.slider-content {
  width: 85%;
  margin: auto;
  margin-top: 3rem;
}

.slider-datalist {
  display: grid;
  margin-top: 1rem;
}
.slider-datalist a {
  margin-top: 0.5rem;
  color: #039874;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.slider-datalist a:hover {
  text-decoration: underline;
}

// Media Queries

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .content {
    width: 85%;
  }
  h2 {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  .slider {
    max-width: 100vw;
  }
}

// Small - Medium devices
@media (min-width: 600px) and (max-width: 634px) {
  .content {
    width: 85%;
  }
  h2 {
    font-size: 1.8rem;
    line-height: 1.2;
  }
  .slider {
    max-width: 100vw;
  }
}

// Small - Medium devices
@media (min-width: 635px) and (max-width: 819px) {
  .content {
    width: 85%;
  }

  .slider {
    max-width: 100vw;
  }
}

// Big phones and Small Tablets
@media (min-width: 820px) and (max-width: 944px) {
  .content {
    width: 85%;
  }
}

// Tablets  and small screens
@media (min-width: 945px) and (max-width: 1199px) {
  .content {
    width: 85%;
  }
}

// Tablets Landscape and Medium  Screens
@media (min-width: 1200px) and (max-width: 1440px) {
  .content {
    width: 85%;
  }
}

// Large - Extra Large Screens
@media (min-width: 1441px) and (max-width: 1920px) {
  .content {
    width: 75%;
  }
}
